var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"datatable",class:{ 'horizontal-scroll': _vm.enableHorizontalScroll }},[_c('table',{class:{ loading: _vm.isLoading }},[_c('thead',[_vm._t("secondary-header"),_c('tr',_vm._l((_vm.columns),function(column,index){return _c('th',{key:("ch" + index),class:[column.value, column.type],style:(_vm.getStyle(column)),attrs:{"scope":"col"}},[_c('div',{staticClass:"datatable-header"},[_c('div',{staticClass:"datatable-header-title"},[_vm._v(_vm._s(column.header))]),_c('div',{staticClass:"datatable-filter-wrapper"},[(_vm.filters && _vm.filters[column.value])?_c('div',{staticClass:"datatable-filter",class:{
                                    'datatable-filter--selected':
                                        _vm.filters[column.value].selected !== -1,
                                }},[_c('Menu',{attrs:{"button-icon":"filter-icon"}},[_c('DropDownItem',{staticClass:"dropdown__item",class:{
                                            'dropdown__item--selected':
                                                _vm.filters[column.value].selected == -1,
                                        },on:{"click":function($event){return _vm.clickFilter(("filter-" + (column.value)), {
                                                column: column.value,
                                                item: -1,
                                            })}}},[_vm._v(" All ")]),_vm._l((_vm.filters[column.value].items),function(filterItem){return _c('DropDownItem',{key:("" + (filterItem.value)),staticClass:"dropdown__item",class:{
                                            'dropdown__item--selected':
                                                _vm.filters[column.value].selected ==
                                                filterItem.value,
                                        },on:{"click":function($event){return _vm.clickFilter(("filter-" + (column.value)), {
                                                column: column.value,
                                                item: filterItem.value,
                                            })}}},[_c('span',{attrs:{"title":filterItem.helpText}},[_vm._v(_vm._s(filterItem.title))])])})],2)],1):_vm._e()])])])}),0)],2),_c('tbody',[(_vm.hasNoData)?_c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":_vm.columns.length}},[_vm._v(_vm._s(_vm.noData))])]):_vm._e(),(_vm.isLoading)?_c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":_vm.columns.length}},[_vm._v("Is loading...")])]):_vm._l((_vm.data.items),function(item,index){return [_c('tr',{key:("cr" + index),staticClass:"row",class:{
                        'row--has-details': item.hasDetails,
                        'row--is-clickable': _vm.clickableRows,
                    },on:{"click":function($event){return _vm.$emit('rowClick', $event, item)}}},_vm._l((_vm.columns),function(column,indexColumn){return _c('td',{key:("cd" + indexColumn),class:[column.type, column.class],style:(_vm.getStyle(column))},[(column.type !== 'slot' && item.redirectUrl)?_c('router-link',{attrs:{"to":item.redirectUrl}},[_vm._v(" "+_vm._s(_vm.formatValue(column, item))+" ")]):(column.type === 'slot')?_vm._t(column.value,null,{"item":item,"index":index}):[_vm._v(" "+_vm._s(_vm.formatValue(column, item))+" ")]],2)}),0),(item.hasDetails)?_c('tr',{key:("details" + index),staticClass:"row--details"},[_c('td',{attrs:{"colspan":_vm.columns.length}},[_vm._t("details",null,{"item":item,"index":index})],2)]):_vm._e()]})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }