<template>
    <div class="table">
        <Datatable :data="data" :columns="tableColumns">
            <template v-slot:secondary-header>
                <tr>
                    <template v-for="(column, index) in tableColumns">
                        <th
                            v-if="!column.colspan && !column.colGrouped"
                            :key="`sh${index}`"
                            scope="col"
                        ></th>
                        <th
                            v-if="column.colspan"
                            :key="`sh${index}`"
                            scope="colgroup"
                            :colspan="column.colspan"
                            class="secondary-header-group"
                        >
                            {{ column.secondaryHeader }}
                        </th>
                    </template>
                </tr>
            </template>
        </Datatable>
    </div>
</template>

<script>
import Datatable from '@/components/Datatable';
import { deepCopyArray } from '@/utils';
import localeMap from '@/i18n';

const STATIC_COLUMN_HEADERS = [
    {
        value: 'channelName',
        header: 'CHANNEL NAME',
        type: 'text',
        width: '160px',
    },
    {
        value: 'channelCode',
        header: 'CHANNEL CODE',
        type: 'text',
        width: '160px',
    },
    {
        value: 'totalMembers',
        header: 'TOTAL MEMBERS',
        type: 'text',
        width: '160px',
    },
    {
        value: 'locale',
        header: 'LOCALE',
        type: 'text',
        width: '160px',
    },
];

const DYNAMIC_COLUMN_HEADERS = [
    {
        value: '',
        header: 'STARTS (N)',
        type: 'text',
        width: '160px',
        colspan: 3,
        secondaryHeader: '',
    },
    {
        value: '',
        header: 'COMPLETES (N)',
        type: 'text',
        width: '160px',
        colGrouped: true,
    },
    {
        value: '',
        header: 'RESPONSE RATE (%)',
        type: 'text',
        width: '160px',
        colGrouped: true,
    },
];

export default {
    name: 'ChannelGroupReportTable',
    components: {
        Datatable,
    },
    props: {
        tableRows: {
            type: Array,
            default: () => [],
        },
        tags: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        data() {
            const data = { items: [] };
            data.items = this.tableRows.map((channel) => {
                const convoCells = this.makeConvoCells(channel.convos);
                return {
                    ...channel,
                    ...convoCells,
                    locale: localeMap[channel.locale] && localeMap[channel.locale].name,
                };
            });
            return data;
        },
        tableColumns() {
            if (!this.tags.length) {
                return STATIC_COLUMN_HEADERS;
            }
            const dynamicHeaders = this.tags.map((tag, index) => {
                const columns = deepCopyArray(DYNAMIC_COLUMN_HEADERS);
                columns[0].value = `starts|${tag.name}`;
                columns[0].secondaryHeader = tag.name;
                columns[1].value = `completes|${tag.name}`;
                columns[2].value = `response|${tag.name}`;

                const className = index % 2 === 0 ? 'bg-color-light-gray' : '';
                for (let i = 0; i < columns.length; i++) {
                    columns[i].class = className;
                }
                return columns;
            });
            return [...STATIC_COLUMN_HEADERS, ...dynamicHeaders.flat()];
        },
    },
    methods: {
        makeConvoCells(convos) {
            const columns = {};
            for (const convo of convos) {
                columns[`starts|${convo.tagName}`] = convo.chatStarted;
                columns[`completes|${convo.tagName}`] = convo.chatCompleted;
                columns[`response|${convo.tagName}`] = convo.responseRate
                    ? `${convo.responseRate}%`
                    : '0%';
            }
            return columns;
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ {
    .datatable {
        overflow-x: scroll;
    }
}
</style>
