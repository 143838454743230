<script>
import { Breadcrumb } from '@/store/models/breadcrumb.dto.ts';

const makeBreadcrumb = (text, link) => {
    const breadcrumb = new Breadcrumb();
    breadcrumb.text = text;
    if (link) {
        breadcrumb.link = link;
    }
    return breadcrumb;
};

export default {
    name: 'BreadcrumbsMixin',
    data: () => ({
        breadcrumbs: [],
    }),
    methods: {
        addBreadcrumb(text, link) {
            this.breadcrumbs.push(makeBreadcrumb(text, link));
        },
    },
};
</script>
