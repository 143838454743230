import {
    IChannelGroupReportCSVRow,
    IChannelReportRow,
} from '../models/interfaces/channelGroupReport.interface';
import { IContentItemReportResponse, IReportRow } from '../models/interfaces/reports.interface';
import { ReportBreak } from '../models/reportBreak';
import i18n from '@/i18n';

const mapReportResponse = (item: IContentItemReportResponse): IReportRow => ({
    SrNo: item.questionIndex ? item.questionIndex.toString() : '',
    'Message/Answer': item.title,
    Type: item.type || '',
    Total: `${item.count.total}`,
    Web: `${item.count.web}`,
    App: `${item.count.app}`,
});

export function getFinalReportData(reportData: IContentItemReportResponse[]): IReportRow[] {
    return reportData.map(mapReportResponse);
}

export function getFinalReportBreakData(
    reportData: IContentItemReportResponse[],
    reportBreakData: ReportBreak[],
) {
    if (reportData.length === 0 || reportBreakData.length === 0) {
        return [];
    }
    const finalReportData: Partial<IReportRow>[] = [];
    const firstHeader = getFirstHeader(reportBreakData);
    const secondHeader = getSecondHeader(reportBreakData);
    finalReportData.push(firstHeader);
    finalReportData.push(secondHeader);
    for (const item of reportData) {
        const contentItemAnswerId = item.id;
        let reportRowFinal: Partial<IReportRow> = {};
        let reportRow = mapReportResponse(item);
        for (const reportBreak of reportBreakData) {
            let breakObject = {};
            for (const answer of reportBreak['answers']) {
                const breakItemAnswerId = answer.id;
                const key = `${item.contentItemId}|${contentItemAnswerId}|${breakItemAnswerId}`;
                const title = answer.title;
                breakObject = {
                    [title]: reportBreak['cells'][key] || '',
                };
                reportRow = { ...reportRow, ...breakObject };
            }
            reportRowFinal = { ...reportRow, ...breakObject };
        }
        finalReportData.push(reportRowFinal);
    }
    return finalReportData;
}

function getFirstHeader(reportBreakData: ReportBreak[]) {
    let firstHeader = {
        SrNo: '',
        'Message/Answer': '',
        Type: '',
        Total: '',
        Web: '',
        App: '',
    };
    for (const reportBreak of reportBreakData) {
        let newContent: boolean = true;
        const title = reportBreak['title'];
        for (const answer of reportBreak['answers']) {
            const answerTitle = answer.title;
            firstHeader = { ...firstHeader, [answerTitle]: newContent ? title : '' };
            newContent = false;
        }
    }
    return firstHeader;
}

function getSecondHeader(reportBreakData: ReportBreak[]) {
    let secondHeader = {
        SrNo: 'SrNo',
        'Message/Answer': 'Message/Answer',
        Type: 'Type',
        Total: 'Total',
        Web: 'Web',
        App: 'App',
    };
    for (const reportBreak of reportBreakData) {
        let breakObject = {};
        for (const answer of reportBreak['answers']) {
            const title = answer.title;
            breakObject = {
                [title]: title,
            };
            secondHeader = { ...secondHeader, ...breakObject };
        }
    }
    return secondHeader;
}

export function getChannelGroupReportData(
    channelGroupData: IChannelReportRow[],
    selectedTags: string[],
): IChannelGroupReportCSVRow[] {
    const finalReportData: IChannelGroupReportCSVRow[] = [];
    const firstHeader = getChannelGroupFirstHeader(selectedTags);
    const secondHeader = getChannelGroupSecondHeader(selectedTags);
    finalReportData.push(firstHeader);
    finalReportData.push(secondHeader);
    let index = 1;
    const localeMap = i18n as any;
    for (const channelData of channelGroupData) {
        let channelReportData: IChannelGroupReportCSVRow = {
            srNo: index.toString(),
            locale: (localeMap[channelData.locale] && localeMap[channelData.locale].name) || '',
            channelCode: channelData.channelCode,
            channelName: channelData.channelName,
            totalMembers: channelData.totalMembers?.toString(),
        };
        for (const convoData of channelData.convos) {
            const convoReportData: any = {};
            convoReportData[`start|${convoData.tagName}`] = convoData.chatStarted;
            convoReportData[`completes|${convoData.tagName}`] = convoData.chatCompleted;
            convoReportData[`responseRate|${convoData.tagName}`] = convoData.responseRate;
            channelReportData = { ...channelReportData, ...convoReportData };
        }
        finalReportData.push(channelReportData);
        index++;
    }
    return finalReportData;
}

function getChannelGroupSecondHeader(selectedTags: string[]): IChannelGroupReportCSVRow {
    let header: IChannelGroupReportCSVRow = {
        srNo: 'Sr No',
        locale: 'Locale',
        channelCode: 'Channel Code',
        channelName: 'Channel Name',
        totalMembers: 'Total Member',
    };
    for (const tagName of selectedTags) {
        const convoHeader: any = {};
        convoHeader[`start|${tagName}`] = 'Total starts';
        convoHeader[`completes|${tagName}`] = 'Total Completes';
        convoHeader[`responseRate|${tagName}`] = 'Response Rate';
        header = { ...header, ...convoHeader };
    }
    return header;
}

function getChannelGroupFirstHeader(selectedTags: string[]): IChannelGroupReportCSVRow {
    let header: IChannelGroupReportCSVRow = {
        srNo: '',
        locale: '',
        channelCode: '',
        channelName: '',
        totalMembers: '',
    };
    for (const tagName of selectedTags) {
        const convoHeader: any = {};
        convoHeader[`start|${tagName}`] = '';
        convoHeader[`completes|${tagName}`] = tagName;
        convoHeader[`responseRate|${tagName}`] = '';
        header = { ...header, ...convoHeader };
    }

    return header;
}
