<template>
    <div>
        <FullPageLoadingSpinner :show="pageIsLoading" />

        <PageHeading
            :breadcrumbs="breadcrumbs"
            :title="`${isNewReport ? 'Create' : 'Edit'} Report`"
        />

        <Input
            id="report-id"
            v-model="nameModel"
            name="generateId('app-name', data.id)"
            type="text"
            label="Name"
            class="input__name"
            :errors="errors.name"
            required
            placeholder="Enter the name of the report"
        />
        <div class="input-options">
            <Tags
                v-model="tagsModel"
                placeholder="Add group tag..."
                label="Tags"
                class="input__tag"
                :autocomplete-items="autocompleteTags"
                :add-from-paste="true"
                :separators="[' ', ';']"
                :disabled="false"
                :max-tags="20"
                :errors="errors.tagConfig"
                @autocomplete="(val) => (autocompleteTagsQuery = val)"
            />
            <IconButton
                v-if="showDownloadButton"
                v-tooltip="`Download CSV`"
                icon="download2"
                class="action-icon m-r-2 m-b-1"
                @click="downloadReport"
            />
            <IconButton
                v-if="!isNewReport"
                v-tooltip="`Update Data`"
                icon="update"
                class="action-icon m-r-2 m-b-1"
                @click="updateData"
            />
        </div>

        <ChannelGroupReportTable :table-rows="reportRows" :tags="tagsModel" class="m-b-3" />

        <Stepper
            show-back-button
            show-next-button
            :show-buttons-slot="true"
            :next-button-is-disabled="false"
            :next-button-text="nextButtonText"
            :is-loading="isSaving"
            @backButtonOnClick="navigateToList"
            @nextButtonOnClick="saveAndExit"
        >
        </Stepper>
    </div>
</template>

<script>
import ChannelGroupReportTable from '@/components/ChannelGroupReportTable';
import PageHeading from '@/components/ui/PageHeading';
import { mapActions, mapGetters, mapState } from 'vuex';
import { Tags } from '@/components/forms';
import { getChannelGroupReportData } from '@/store/services/reportService';
import FullPageLoadingSpinner from '../components/ui/FullPageLoadingSpinner';
import { downloadCSV } from '../utils/downloadCsv';
import { LoadingFlag } from '@/store/enums/loadingIds.enum.ts';
import Input from '@/components/forms/Input';
import { TagType } from '@/store/enums';
import Stepper from '@/components/ui/Stepper';
import BreadcrumbsMixin from '@/mixins/BreadcrumbsMixin';
import IconButton from '@/components/ui/IconButton';

export default {
    name: 'ReportDetails',
    components: {
        ChannelGroupReportTable,
        FullPageLoadingSpinner,
        PageHeading,
        IconButton,
        Stepper,
        Input,
        Tags,
    },
    mixins: [BreadcrumbsMixin],
    data() {
        return {
            autocompleteTagsQuery: '',
            isSaving: false,
        };
    },
    computed: {
        ...mapGetters('tags', ['tagsByType']),
        ...mapGetters('channelGroupReport', ['isChangedReport', 'isNewReport']),
        ...mapState('channelGroupReport', ['report', 'reportRows', 'errors']),
        nameModel: {
            get() {
                return this.report.name;
            },
            set(name) {
                this.$store.dispatch('channelGroupReport/setReportName', name);
            },
        },
        tagsModel: {
            get() {
                const selectedTags = this.report.tagConfig || [];
                return this.groupTags.filter((tag) => selectedTags.includes(tag.name));
            },
            set(tags) {
                this.$store.dispatch('channelGroupReport/setReportTags', tags);
            },
        },
        groupTags() {
            return this.tagsByType(TagType.group);
        },
        autocompleteTags() {
            const query = this.autocompleteTagsQuery.toUpperCase();
            return this.groupTags.filter((tag) => tag.name.toUpperCase().includes(query));
        },
        pageIsLoading() {
            return this.$wait.is(LoadingFlag.ChannelGroupGenerateReport);
        },
        nextButtonText() {
            return this.isNewReport || this.isChangedReport ? 'Save Changes' : 'Finish';
        },
        showDownloadButton() {
            return this.reportRows.length;
        },
    },
    async mounted() {
        const isNewReport = this.$route.params.reportId === undefined;
        if (isNewReport) {
            this.clearReportData();
        } else {
            await this.setReportData(this.$route.params.reportId);
        }
        this.addBreadcrumb('Reports', { name: 'list-reports' });
        this.addBreadcrumb(this.isNewReport ? 'New report' : this.report.name);
        this.getTags();
    },
    methods: {
        ...mapActions('tags', ['getTags']),
        ...mapActions('channelGroupReport', [
            'saveReport',
            'updateReport',
            'setReportData',
            'clearReportData',
            'generateReportData',
        ]),
        async updateData() {
            await this.generateReportData();
            await this.updateReport();
        },
        downloadReport() {
            const tagNames = this.report.tagConfig;
            const reportData = getChannelGroupReportData(this.reportRows, tagNames);
            const filename = tagNames.join('|') + '_group';
            const title = `,Tags Selected : ${tagNames.join(',')}`;
            downloadCSV(filename, title, reportData);
        },
        navigateToList() {
            this.$router.push({ name: 'list-reports' });
        },
        async saveAndExit() {
            this.isSaving = true;
            const isSaved = await this.saveReport();
            if (isSaved) {
                this.navigateToList();
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.input-options {
    display: flex;
    align-items: center;

    & > div {
        margin-right: 2em;
    }
    .input__tag {
        flex: 0 0 90%;
    }
    .action-icon {
        font-size: $font-size-base;
    }
}
.input__name {
    width: 90%;
}
</style>
