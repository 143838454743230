import { ExportToCsv } from 'export-to-csv';

export function downloadCSV(filename: string, title: string, data: any[]) {
    const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        filename: filename,
        title: title,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: false,
    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(data);
}
