<template>
    <div class="datatable" :class="{ 'horizontal-scroll': enableHorizontalScroll }">
        <table :class="{ loading: isLoading }">
            <thead>
                <slot name="secondary-header" />

                <tr>
                    <th
                        v-for="(column, index) in columns"
                        :key="`ch${index}`"
                        :class="[column.value, column.type]"
                        :style="getStyle(column)"
                        scope="col"
                    >
                        <div class="datatable-header">
                            <div class="datatable-header-title">{{ column.header }}</div>
                            <div class="datatable-filter-wrapper">
                                <div
                                    v-if="filters && filters[column.value]"
                                    class="datatable-filter"
                                    :class="{
                                        'datatable-filter--selected':
                                            filters[column.value].selected !== -1,
                                    }"
                                >
                                    <Menu button-icon="filter-icon">
                                        <DropDownItem
                                            class="dropdown__item"
                                            :class="{
                                                'dropdown__item--selected':
                                                    filters[column.value].selected == -1,
                                            }"
                                            @click="
                                                clickFilter(`filter-${column.value}`, {
                                                    column: column.value,
                                                    item: -1,
                                                })
                                            "
                                        >
                                            All
                                        </DropDownItem>

                                        <DropDownItem
                                            v-for="filterItem in filters[column.value].items"
                                            :key="`${filterItem.value}`"
                                            class="dropdown__item"
                                            :class="{
                                                'dropdown__item--selected':
                                                    filters[column.value].selected ==
                                                    filterItem.value,
                                            }"
                                            @click="
                                                clickFilter(`filter-${column.value}`, {
                                                    column: column.value,
                                                    item: filterItem.value,
                                                })
                                            "
                                        >
                                            <span :title="filterItem.helpText">{{
                                                filterItem.title
                                            }}</span>
                                        </DropDownItem>
                                    </Menu>
                                </div>
                            </div>
                        </div>
                    </th>
                </tr>
            </thead>

            <tbody>
                <tr v-if="hasNoData">
                    <td :colspan="columns.length" class="text-center">{{ noData }}</td>
                </tr>

                <tr v-if="isLoading">
                    <td :colspan="columns.length" class="text-center">Is loading...</td>
                </tr>

                <template v-for="(item, index) in data.items" v-else>
                    <tr
                        :key="`cr${index}`"
                        class="row"
                        :class="{
                            'row--has-details': item.hasDetails,
                            'row--is-clickable': clickableRows,
                        }"
                        @click="$emit('rowClick', $event, item)"
                    >
                        <td
                            v-for="(column, indexColumn) in columns"
                            :key="`cd${indexColumn}`"
                            :class="[column.type, column.class]"
                            :style="getStyle(column)"
                        >
                            <router-link
                                v-if="column.type !== 'slot' && item.redirectUrl"
                                :to="item.redirectUrl"
                            >
                                {{ formatValue(column, item) }}
                            </router-link>

                            <slot
                                v-else-if="column.type === 'slot'"
                                :name="column.value"
                                :item="item"
                                :index="index"
                            />

                            <template v-else>
                                {{ formatValue(column, item) }}
                            </template>
                        </td>
                    </tr>

                    <tr v-if="item.hasDetails" :key="`details${index}`" class="row--details">
                        <td :colspan="columns.length">
                            <slot name="details" :item="item" :index="index" />
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script>
import { format, parseISO } from 'date-fns';
import Menu from '@/components/ui/Menu';
import DropDownItem from '@/components/ui/DropDown/DropDownItem';

export default {
    name: 'Datatable',
    components: { Menu, DropDownItem },
    props: {
        isLoading: {
            type: Boolean,
            required: false,
        },
        noData: {
            type: String,
            default: 'No data',
        },
        data: {
            type: Object,
            default: () => ({}),
        },
        columns: {
            type: Array,
            required: true,
        },
        filters: {
            type: Object,
            default: () => ({}),
        },
        clickableRows: {
            type: Boolean,
            default: false,
        },
        enableHorizontalScroll: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        hasNoData() {
            return (
                (!this.data || (this.data && (!this.data.items || this.data.items.length === 0))) &&
                !this.isLoading
            );
        },
    },
    methods: {
        getStyle(column) {
            const style = {};
            if (column.width) {
                style.width = column.width;
            }
            return style;
        },
        formatValue(column, item) {
            const i = item[column.value];
            if (column.type === 'date') {
                return i ? format(parseISO(i), column.format) : '';
            }
            if (column.type === 'boolean') {
                return i ? 'enabled' : 'disabled';
            }
            return i;
        },
        clickFilter(ref, data) {
            this.$emit('filterClick', data);
        },
    },
};
</script>

<style lang="scss">
.datatable {
    width: 100%;
    position: relative;

    table {
        width: 100%;
        thead,
        tbody {
            display: table;
            width: 100%;
            table-layout: fixed;
        }
        thead {
            tr {
                height: 40px;

                &:not(:first-child) {
                    th {
                        border-top: 1px solid $table-row-border-color;
                    }
                }

                &:hover {
                    cursor: default;
                }
            }
        }
        tbody {
            tr {
                height: 40px;

                &:hover {
                    background: $table-row-hover;
                }
            }
        }
        &.loading {
            tbody {
                opacity: 0.5;
                position: relative;
                &:after {
                    position: absolute;
                    top: 0;
                    left: 0%;
                    right: 0;
                    bottom: 0;
                    opacity: 1;
                    text-align: center;
                    color: $table-loading-color;
                    content: '';
                }
            }
        }
        th,
        td {
            vertical-align: middle;
        }
        th {
            font-size: $font-size-xs;
            color: $alternate-text-color;
        }
        td {
            border-top: 1px solid $table-row-border-color;
            font-size: $font-size-sm;
            color: $table-row-color;
        }

        tr:not(.row--details) td:not(.table-row-actions) tr:not(.datatable-wrap-content),
        th {
            padding: 4px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            a {
                width: 100%;
            }
        }
        .number {
            text-align: right;
        }
        .date {
            text-align: left;
        }
        .dropdown__item {
            color: $table-row-color;
            &--selected {
                background: $table-row-hover;
            }
        }
    }
}

.datatable-header-title {
    display: inline-block;
}

.datatable-filter-wrapper {
    display: inline-block;
}

.datatable-header {
    text-align: left;
    white-space: nowrap;
}

.datatable-filter {
    position: absolute;
    top: 6px;
    margin-left: 10px;

    &--selected {
        .icon-button {
            background: $icon-button-bg-hover-color;
        }
    }
}
.horizontal-scroll {
    overflow-x: scroll;
}
.datatable-wrap-content {
    padding: 4px;
    white-space: break-spaces;
}
</style>

<style lang="scss" scoped>
.row {
    &--is-clickable {
        cursor: pointer;
    }
}
</style>
